$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                scrollTo(target);
                return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(".js-nav").toggleClass("active");
        $("body").toggleClass("freeze");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *

    $(".js-accordion-button").click(function () {
        $(this).toggleClass("active").siblings(".js-accordion").slideToggle();
        $(this).parents(".js-accordion-inner").siblings().find(".js-accordion").slideUp();
    });

    // $(".js-accordion-wrapper").each(function () {
    //     $(this).find(".js-accordion-inner").eq(0).find(".js-accordion-button").trigger("click");
    // })

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * js-slick
    // *
    // *
    $('.js-slick').slick({
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
    });




});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
});

// * * * * * * * * * * * * * * * * * * * * * * * * *
// * Scroll Top
// *
// *
// When the user scrolls down 20px from the top of the document, show the button
// Get the button:
let logo = document.getElementById("js-fixed-logo");
let scrollButton = document.getElementById("js-scroll-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () { scrollFunction() };

function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        scrollButton.style.color = "#b9c8b9";
        scrollButton.style.display = "block";
        scrollButton.style.background = "white";

    } else {
        scrollButton.style.color = "transparent";
        scrollButton.style.background = "transparent";
    }
    if (window.pageYOffset > window.innerHeight * 0.9) {
        logo.style.display = "block";
    } else {
        logo.style.display = "none";
    }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

// * * * * * * * * * * * * * * * * * * * * * * * * *
// * invert Logo
// *
// *
//! only works on last element
// $(window).on("load resize scroll", function() {
//     var invertLogoPos = $(".fixedLogo").position().top;

//     $(".js-invert-section").each(function() {
//         var sectionStart = $(this).offset().top - $(window).scrollTop(),
//             sectionEnd = $(this).offset().top + $(this).outerHeight() - $(window).scrollTop();

//         if (invertLogoPos > sectionStart && invertLogoPos < sectionEnd) {
//             $(".fixedLogo").addClass("inverted");
//         }else {
//             $(".fixedLogo").removeClass("inverted");
//         }
//     });

// });